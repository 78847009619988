import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import SelectCamera from "./SelectCamera";
import Recorder from "./Recorder";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
import * as blazeface from "@tensorflow-models/blazeface";
import * as poseDetection from "@tensorflow-models/pose-detection";

const Camera = () => {
  const webcamRef = useRef(null);
  const [videoURL, setVideoURL] = useState(null);
  const [devices, setDevices] = useState([]); // 비디오 장치 목록
  const [selectedDeviceId, setSelectedDeviceId] = useState(""); // 선택된 장치 ID

  const [model, setModel] = useState(null); // Blazeface 모델 상태
  const [modelType, setModelType] = useState(null);
  useEffect(() => {
    const getDevices = async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = mediaDevices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
      if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId); // 첫 번째 장치 선택
      }
    };
    const loadBlazefaceModel = async () => {
      try {
        await tf.ready(); // TensorFlow.js가 준비될 때까지 대기
        await tf.setBackend("webgpu"); // WebGPU 설정
        const modelConfig = {
          runtime: "tfjs",
        };
        const loadedModel = await blazeface.load(modelConfig);
        setModel(loadedModel);
        setModelType("face");
      } catch (error) {
        console.error("Failed to load model:", error);
      }
    };

    const loadBlazePoseModel = async () => {
      try {
        await tf.ready(); // TensorFlow.js가 준비될 때까지 대기
        await tf.setBackend("webgpu"); // WebGPU 설정
        const modelConfig = {
          runtime: "tfjs",
          modelType: "lite", // 모델 타입을 'lite', 'full', 'heavy' 중 하나로 설정
          //   modelType: poseDetection.SupportedModels.BlazePose, // 모델 설정
        };
        const loadedModel = await poseDetection.createDetector(
          poseDetection.SupportedModels.BlazePose,
          modelConfig
        );
        setModel(loadedModel);
        setModelType("pose");
      } catch (error) {
        console.error("Failed to load model:", error);
      }
    };

    getDevices(); // 장치 목록 불러오기
    if (modelType === "pose") {
      loadBlazePoseModel();
    } else {
      loadBlazefaceModel();
    }
  }, [modelType]); // 모델 타입이 변경될 때마다 모델 로드

  const handleModelChange = (newModelType) => {
    console.log(modelType);

    setModelType(newModelType); // 모델 타입 변경
  };
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            width={640}
            height={480}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              deviceId: selectedDeviceId
                ? { exact: selectedDeviceId }
                : undefined,
            }}
          />
          <div>
            <SelectCamera
              devices={devices}
              selectedDeviceId={selectedDeviceId}
              setSelectedDeviceId={setSelectedDeviceId}
            />
          </div>
          {/* 모델 타입 선택 버튼 */}
          <div>
            <button onClick={() => handleModelChange("face")}>
              Blazeface 모델
            </button>
            <button onClick={() => handleModelChange("pose")}>
              BlazePose 모델
            </button>
          </div>

          <Recorder
            model={model}
            modelType={modelType}
            webcamRef={webcamRef}
            setVideoURL={setVideoURL}
          />
        </div>

        <div>
          {videoURL && (
            <div>
              <video src={videoURL} controls width="600" height="400" />
              <a href={videoURL} download="recording.webm">
                Download Video
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Camera;
