import React, { useState, useRef, useCallback, useEffect } from "react";

const Recorder = ({ model, modelType, webcamRef, setVideoURL }) => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunks = useRef([]);

  const [outputs, setOutputs] = useState([]); // 얼굴 인식 결과 상태
  const [isDetecting, setIsDetecting] = useState(true); // 얼굴 인식 여부

  // 녹화 시작 함수
  const startRecording = useCallback(() => {
    const stream = webcamRef.current.stream;
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=vp8",
    });
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.current.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(chunks.current, { type: "video/webm" });
      setVideoURL(URL.createObjectURL(blob));
      chunks.current = [];
    };
    mediaRecorderRef.current.start();
    setRecording(true);
  }, []);

  // 녹화 정지 함수
  const stopRecording = useCallback(() => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  }, []);

  const detectFace = async () => {
    if (webcamRef.current && model) {
      const video = webcamRef.current.video;
      // const predictions = await model.estimatePoses(video, false); // 얼굴 추정
      if (model.estimateFaces) {
        const predictions = await model.estimateFaces(video, false); // 얼굴 추정
        // console.log(predictions);
        setOutputs(predictions); // 추정된 얼굴 정보 상태 업데이트
        requestAnimationFrame(detectFace);
      }
    }
  };

  const detectPose = async () => {
    if (webcamRef.current && model) {
      const video = webcamRef.current.video;
      if (model.estimatePoses) {
        const predictions = await model.estimatePoses(video, false); // 얼굴 추정
        setOutputs(predictions); // 추정된 얼굴 정보 상태 업데이트
        // console.log(predictions);
        requestAnimationFrame(detectPose);
      }
    }
  };

  const handleStartDetection = () => {
    setIsDetecting(true);
    if (modelType === "face") {
      requestAnimationFrame(detectFace); // 추론 시작
    } else {
      requestAnimationFrame(detectPose); // 추론 시작
    }
  };

  const handleStopDetection = () => {
    setIsDetecting(false);
    setOutputs([]); // 얼굴 인식 결과 초기화
  };

  return (
    <div>
      {!recording ? (
        <button onClick={startRecording}>Start Recording</button>
      ) : (
        <button onClick={stopRecording}>Stop Recording</button>
      )}

      {/* 얼굴 인식 결과 표시 */}
      {modelType === "face" && outputs.length > 0 && outputs[0].landmarks && (
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
          {outputs.map((output, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                top: output.topLeft[1],
                left: output.topLeft[0],
                width: output.bottomRight[1] - output.topLeft[1],
                height: output.bottomRight[0] - output.topLeft[0],
                border: "2px solid red",
                pointerEvents: "none",
              }}
            >
              {/* 얼굴의 keypoint를 그리기 */}
              {output.landmarks.map((point, pointIndex) => (
                <div
                  key={pointIndex}
                  style={{
                    position: "absolute",
                    top: point[1] - output.topLeft[1], // 중앙으로 맞추기 위해 -5
                    left: point[0] - output.topLeft[0], // 중앙으로 맞추기 위해 -5
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                    backgroundColor: "blue",
                    pointerEvents: "none",
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      )}

      {modelType === "pose" && outputs.length > 0 && outputs[0].keypoints && (
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
          {outputs.map((output, index) => (
            <div key={index}>
              {/*  keypoint를 그리기 */}
              {output.keypoints.map((point, pointIndex) => (
                <div
                  key={pointIndex}
                  style={{
                    position: "absolute",
                    top: point.y, // 중앙으로 맞추기 위해 -5
                    left: point.x, // 중앙으로 맞추기 위해 -5
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                    backgroundColor: "blue",
                    pointerEvents: "none",
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      )}

      {/* 얼굴 인식 시작/정지 버튼 */}
      <div style={{ marginTop: 20 }}>
        {!isDetecting ? (
          <button onClick={handleStartDetection}>Start Detection</button>
        ) : (
          <button onClick={handleStopDetection}>Stop Detection</button>
        )}
      </div>
    </div>
  );
};

export default Recorder;
