const SelectCamera = ({ devices, selectedDeviceId, setSelectedDeviceId }) => {
  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };
  return (
    <>
      <select onChange={handleDeviceChange} value={selectedDeviceId}>
        {devices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Camera ${devices.indexOf(device) + 1}`}
          </option>
        ))}
      </select>
    </>
  );
};
export default SelectCamera;
