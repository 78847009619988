// import CameraRecorder from "../../components/CameraRecorder";
import Camera from "../../components/Webcam/Camera";
const WebCam = () => {
  return (
    <>
      <div>
        <Camera />
        {/* <CameraRecorder /> */}
      </div>
    </>
  );
};

export default WebCam;
